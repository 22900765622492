<template>
  <div class="py-1" :class="{ 'd-flex': isMobile() }">
    <a :href="`/goods/list?sku=${item.sku}`" target="_blank">{{ item.sku }}</a>
    <br />
    <v-icon
      :style="isMobile() ? 'font-size: 18px' : ''"
      color="primary"
      @click="downloadGoodsHistory(item.id_goods || item.id)"
      >mdi-download</v-icon
    >
    <v-icon
      :style="isMobile() ? 'font-size: 18px' : ''"
      :title="`Sao chép lịch sử vị trí SKU: ${item.sku}`"
      color="primary"
      class="cursor-pointer"
      @click="getCellsForCopy(item)"
      >mdi-content-copy</v-icon
    >
    <v-icon
      :style="isMobile() ? 'font-size: 18px' : ''"
      :title="`Sao chép lịch sử vị trí SKU: ${item.sku} (NEW)`"
      color="error"
      class="cursor-pointer"
      @click="getCellsForCopyV2(item)"
      >mdi-content-copy</v-icon
    >

    <div ref="copyRef" style="display: none; white-space: pre">
      <span v-html="copyItemText"></span>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { copyText, downloadExcelFile, isMobile } from "@/libs/helpers";

export default {
  name: "SkuDownload",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    copyItem: {},
    copies: [],
    isWaitingDownload: false,
  }),
  computed: {
    copyItemText() {
      if (!this.copyItem || !this.copyItem.sku) {
        return "";
      }
      let html = `SKU: ${this.copyItem.sku}\n`;
      html += `Barcode: ${this.copyItem.customer_goods_barcode}\n`;
      html += `Tên hàng: ${this.copyItem.goods_name || this.copyItem.name}\n`;
      html += `Vị trí:\n`;
      this.copies.forEach((cp) => {
        html += `${cp.cell_id}\n`;
      });
      return html;
    },
  },
  methods: {
    copyText,
    downloadExcelFile,
    isMobile,
    onDbClick() {
      this.$emit("onDbClick", this.item.sku);
    },
    async getCellsForCopy(item) {
      this.copyItem = { ...item };
      const { data } = await httpClient.post(
        "/goods/v1/cell-history-for-copy",
        {
          sku: this.copyItem.sku,
          id_warehouse: this.copyItem.id_warehouse,
        }
      );
      this.copies = [...data];
      this.$nextTick(() => {
        this.$vToastify.success(this.$t("messages.copy_success"));
        this.copyText(this.$refs.copyRef.textContent);
      });
    },
    async getCellsForCopyV2(item) {
      this.copyItem = { ...item };
      const { data } = await httpClient.post(
        "/goods/v1/cell-history-for-copy-v2",
        {
          sku: this.copyItem.sku,
          id_warehouse: this.copyItem.id_warehouse,
        }
      );
      this.copies = [...data];
      this.$nextTick(() => {
        this.$vToastify.success(this.$t("messages.copy_success"));
        this.copyText(this.$refs.copyRef.textContent);
      });
    },
    async downloadGoodsHistory(id_goods) {
      try {
        this.listenSocket();
        await httpClient.post("/goods/v1/get-in-out-file", { id_goods });
        this.$vToastify.success(this.$t("messages.processing_wait"));
        this.isWaitingDownload = true;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isWaitingDownload = false;
        this.$vToastify.error(errMsg);
      }
    },
    listenSocket() {
      window.socket.on("goods_history_file_path", (data) => {
        if (this.isWaitingDownload) {
          const { file_path } = data;
          const split_file_path = file_path.split("/");
          const filename = split_file_path[split_file_path.length - 1];

          this.downloadExcelFile(
            "/download/v1/goods-history-in-out",
            data,
            filename
          )
            .then((res) => {
              console.log(res);
              this.isLoading = false;
              this.isWaitingDownload = false;
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
            });
        }
      });
    },
  },
  /*mounted() {
    setTimeout(() => {
      this.listenSocket()
    }, 1000)
  }*/
};
</script>

<style scoped></style>
